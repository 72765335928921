import { registerApplication } from 'single-spa'
import { routeMatcher } from '@toasttab/banquet-match-utils'
import { importSpa } from '../utils'

export const financing = () => {
  // --------------------------------------
  // Define layout spas
  // --------------------------------------

  registerApplication({
    name: 'customer-financing-ui',
    activeWhen: ({ pathname }) =>
      Boolean(routeMatcher(['/customer-financing'])(pathname)),
    app: importSpa('customer-financing-ui')
  })
}
