import { registerApplication } from 'single-spa'
import { routeMatcher } from '@toasttab/banquet-match-utils'
import { importSpa } from '../utils'

export const loyaltySignUp = () => {
  // --------------------------------------
  // Define layout spas
  // --------------------------------------

  registerApplication({
    name: 'loyalty-sign-up-page',
    activeWhen: ({ pathname }) =>
      Boolean(routeMatcher(['*/rewardsSignup', '*/rewardsLookup'])(pathname)),
    app: importSpa('loyalty-sign-up-page')
  })
}
