import { registerApplication } from 'single-spa'
import { importSpa } from '../utils'
import { routeMatcher } from '@toasttab/banquet-match-utils'

export const insightsPublicPage = () => {
  const unsubscribeRouteMatch = routeMatcher([
    '/ads-insights/unsubscribe',
    '/ads-insights/unsubscribe/*'
  ])
  registerApplication({
    name: 'insights-public-spa',
    activeWhen: ({ pathname }) => Boolean(unsubscribeRouteMatch(pathname)),
    app: importSpa('insights-public-spa')
  })
}
