import { registerApplication } from 'single-spa'
import { routeMatcher } from '@toasttab/banquet-match-utils'
import { importSpa } from '../utils'

export const loyalty = () => {
  // --------------------------------------
  // Define layout spas
  // --------------------------------------

  registerApplication({
    name: 'loyalty-web',
    activeWhen: ({ pathname }) =>
      Boolean(routeMatcher(['/loyalty/welcome/*'])(pathname)),
    app: importSpa('loyalty-web')
  })
}
