import { FFLookup } from '@toasttab/banquet-types'

export interface BanquetInitialData {
  featureFlags?: FFLookup
}

declare let __TOAST_BANQUET_INITIAL_DATA__: BanquetInitialData

/**
 * getGlobalCustomProps
 * Builds the props object available to public SPAs
 */
export function getGlobalCustomProps(): BanquetInitialData {
  return {
    ...__TOAST_BANQUET_INITIAL_DATA__
  }
}
