import { registerApplication } from 'single-spa'
import { routeMatcher } from '@toasttab/banquet-match-utils'
import { importSpa } from '../utils'
import { RegistrationFunc } from './registration'

export const account: RegistrationFunc = (globalCustomProps) => {
  const accountMatch = routeMatcher(['/account', '/account/*'])
  const { featureFlags } = globalCustomProps

  // --------------------------------------
  // Define layout spas
  // --------------------------------------

  registerApplication({
    // Should be renamed `spa-account-user-activation`
    name: 'spa-user-activation',
    activeWhen: ({ pathname }) =>
      Boolean(accountMatch(pathname)) && /user-activation/i.test(pathname),
    customProps: { featureFlags },
    app: importSpa('spa-user-activation')
  })

  registerApplication({
    // Should be renamed `spa-account-reset-password`
    name: 'spa-account-forgot-password',
    activeWhen: ({ pathname }) =>
      Boolean(accountMatch(pathname)) &&
      /(reset|recover)-password/i.test(pathname),
    app: importSpa('spa-account-forgot-password')
  })
}
