import { registerApplication } from 'single-spa'
import { importSpa } from '../utils'
import {
  getCurrentEnvironment,
  ToastEnvironment
} from '@toasttab/current-environment'

const SPA_DEV_TOOLS = 'spa-dev-tools'

export const devTools = () => {
  const env = getCurrentEnvironment()
  if (env === ToastEnvironment.PROD || env === ToastEnvironment.SANDBOX) {
    return
  }

  registerApplication({
    name: SPA_DEV_TOOLS,
    activeWhen() {
      return true
    },
    app: importSpa(SPA_DEV_TOOLS)
  })
}
