import { registerApplication } from 'single-spa'
import { importSpa } from '../utils'

export const urlShortener = () => {
  // --------------------------------------
  // Define layout spas
  // --------------------------------------
  registerApplication({
    name: 'url-shortener-public-spa',
    activeWhen: '/r/',
    app: importSpa('url-shortener-public-spa')
  })
}
