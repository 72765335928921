import { registerApplication } from 'single-spa'
import { routeMatcher } from '@toasttab/banquet-match-utils'
import { importSpa } from '../utils'

export const preLoginOnboarding = () => {
  // --------------------------------------
  // Define layout spas
  // --------------------------------------

  registerApplication({
    name: 'pre-login-onboarding-spa',
    activeWhen: ({ pathname }) =>
      Boolean(routeMatcher(['/welcome/*'])(pathname)),
    app: importSpa('pre-login-onboarding-spa')
  })
}
