import { registerApplication } from 'single-spa'
import { importSpa } from '../utils'

export const phygital = () => {
  // --------------------------------------
  // Define layout spas
  // --------------------------------------

  registerApplication({
    name: 'phygital-public-spa',
    activeWhen: '/restaurant-jobs/r/',
    app: importSpa('phygital-public-spa')
  })
}
