import { registerApplication } from 'single-spa'
import { routeMatcher } from '@toasttab/banquet-match-utils'
import { importSpa } from '../utils'

export const guestFeedback = () => {
  registerApplication({
    name: 'guest-feedback-public-spa',
    activeWhen: isGuestFeedbackRoute,
    app: importSpa('guest-feedback-public-spa')
  })
}

export const isGuestFeedbackRoute = ({ pathname }: Location) => {
  return Boolean(
    routeMatcher([
      '/:shortUrl/feedback/:restaurantGuid/:checkGuid/:orderGuid/:orderPaymentGuid',
      '/feedback/:restaurantGuid'
    ])(pathname)
  )
}
