import { registerApplication } from 'single-spa'
import { routeMatcher } from '@toasttab/banquet-match-utils'
import { importSpa } from '../utils'

export const invoice = () => {
  // --------------------------------------
  // Define layout spas
  // --------------------------------------

  registerApplication({
    name: 'invoice-pay-web',
    activeWhen: ({ pathname }) =>
      Boolean(
        routeMatcher([
          '/invoice/*',
          '/catering/locations/*',
          '/catering/leads/*'
        ])(pathname)
      ),
    app: importSpa('invoice-pay-web')
  })
}
